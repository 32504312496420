import { EUserMode } from '~interfaces/User';
import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom';
import { useAuth } from '~contexts/Auth';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import FullPageWorkingIndicator, { FullPageContainer } from '~components/indicators/FullPageWorkingIndicator';
import React, { useCallback } from 'react';
import useOrganization from '~hooks/useOrganization';
import useQueryParams from '~hooks/useQueryParams';

export type RouteRequireAuthenticatedProps = {
    loginHint?: string;
    requireAuthenticated?: boolean;
    requireRegistered?: boolean;
    screenHint?: string;
};

type Auth0RedirectQueryParams = {
    login_hint?: string;
    screen_hint?: string;
};

export default function ProtectedRoutes(props: RouteRequireAuthenticatedProps): JSX.Element {
    const { requireAuthenticated = true, requireRegistered = true } = props;
    const { screen_hint, login_hint } = useQueryParams<Auth0RedirectQueryParams>();
    const { t } = useTranslation(['common']);
    const { isReady: authIsReady, auth0Error } = useAuth();
    const {
        isRegistered: userIsRegistered,
        isReady: userIsReady,
        isAuthenticated: userIsAuthenticated,
        isAddressRequired: userIsAddressRequired,
        userMode,
    } = useUser();
    const { isAddressRequired: organizationIsAddressRequired } = useOrganization();
    const location = useLocation();

    const intendedPath = location.pathname + location.search;

    const isOnPath = useCallback(
        (path: string | string[]) =>
            Array.isArray(path) ? path.some((p) => pathMatch(p, intendedPath)) : pathMatch(path, intendedPath),
        [intendedPath],
    );

    const isLoading = !authIsReady || !userIsReady;

    // If auth or user is still loading, show loading indicator
    if (isLoading) return <FullPageWorkingIndicator text={t('general.loadingProfile')} />;

    // If there is an authentication error, send them to the error page
    if (auth0Error) return <Navigate to={{ pathname: '/auth/error', search: window.location.search }} />;

    // If user does not meet authentication requirement, send them to login
    if (requireAuthenticated && !userIsAuthenticated)
        return (
            <FullPageContainer>
                <Navigate
                    to={{ pathname: '/login' }}
                    state={{
                        next: intendedPath,
                        loginHint: props.loginHint ?? login_hint,
                        screenHint: props.screenHint ?? screen_hint,
                    }}
                />
            </FullPageContainer>
        );

    // If user does not meet registration requirement, and is not registering, send them to register
    if (requireRegistered && !userIsRegistered && !isOnPath('/register/*'))
        return <Navigate to={{ pathname: '/register/user', search: `?next=${intendedPath}` }} />;

    // If user address is required, redirect to missing address page
    if (
        userIsAddressRequired &&
        !isOnPath(['/missing/user-address', '/migrate/organization/*', '/register/organizationadmin/*'])
    )
        return (
            <Navigate
                to={{
                    pathname: `/missing/user-address`,
                    search: `?next=${intendedPath}`,
                }}
            />
        );

    // If organization address is required, redirect to missing address page
    if (
        organizationIsAddressRequired &&
        !isOnPath(['/missing/organization-address', '/migrate/organization/*', '/register/organizationadmin/*'])
    )
        return (
            <Navigate
                to={{
                    pathname: `/missing/organization-address`,
                    search: `?next=${intendedPath}`,
                }}
            />
        );

    // Stop SMB users from getting to organization registration
    if (
        userIsAuthenticated &&
        userIsRegistered &&
        isOnPath(['/register/organization/*', '/register/organizationadmin/*', '/migrate/organization/*']) &&
        userMode === EUserMode.SMB
    )
        return <Navigate to={{ pathname: `/` }} />;

    return <Outlet />;
}

function pathMatch(target: string, path: string): boolean {
    return !!matchPath(target, path);
}
